/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-do-hearing-aids-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-do-hearing-aids-work",
    "aria-label": "how do hearing aids work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How do hearing aids work?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have hearing loss and are exploring the world of ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), ", you’re probably wondering how hearing aids work. It’s important to know how they operate — as well as how devices differ — so you can make the best decision for your hearing needs."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "main-components-of-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#main-components-of-hearing-aids",
    "aria-label": "main components of hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Main components of hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are three main components of a hearing aid responsible for amplifying sound:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The microphone picks up sounds around you, which then pass through the sound processor."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The amplifier increases the strength of the processed sounds."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The speaker then transmits those sounds into your ears."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Beyond these main components, hearing aid manufacturers are able to customize their hearing aids with different sound processing algorithms. This is why you’ll see hearing aids that offer increased sound clarity, speech enhancement, ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/background-noise-reduction/",
    className: "c-md-a"
  }, "noise reduction"), " and more. The more intricate\nthe algorithm, the higher the level of technology and features."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "behind-the-ear-vs-in-the-ear-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#behind-the-ear-vs-in-the-ear-hearing-aids",
    "aria-label": "behind the ear vs in the ear hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Behind-the-ear vs. in-the-ear hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With ", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte/",
    className: "c-md-a"
  }, "behind-the-ear (BTE) hearing aids"), ", the microphone, processor and amplifier are housed in the main device that sits behind your ear. The speaker, which sits inside your ear, is attached to the main device via a small tube or wire that sits over top of your ear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "In-the-ear (ITE) or in-the-canal (IIC) hearing aids"), " have the same basic components as BTE devices, but sit just inside your ear or deeper inside your ear canal. These are some of the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/mini-ix/",
    className: "c-md-a"
  }, "smallest hearing aids on the market"), ". Because of their smaller size, they're often not as powerful as BTE hearing aids, but can still offer incredible sound for some who have mild to moderate hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-vs-hearing-amplifiers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-vs-hearing-amplifiers",
    "aria-label": "hearing aids vs hearing amplifiers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids vs. hearing amplifiers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One final note: Hearing aids often get confused with hearing amplifiers. Modern prescription hearing aids have evolved over the years and can be customized by a hearing professional to amplify only the specific sounds you’re missing. Amplifiers simply make all sounds louder. They do not contain sound-processing algorithms or extra features to filter out noise. Since amplifiers can’t be personalized and make everything louder, including sounds you don’t need amplified, they can further damage your hearing if you're not careful."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "schedule-a-hearing-test",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#schedule-a-hearing-test",
    "aria-label": "schedule a hearing test permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Schedule a hearing test"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Getting a hearing test is a great first step toward better hearing. Understanding your level of hearing loss and the specific sounds you’re missing will ensure you get the right device for your needs. Afterall, no two ears are alike! If you’re ready to talk to someone about the right hearing aid for you, let one of our caring hearing specialists help you. They can schedule your hearing test with a professional right in your own neighborhood."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Get started by clicking below and see if you qualify for one of our 45-day no-risk trials today."), "\n", React.createElement(LandingPageCta, {
    copy: "Check if you Qualify",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
